@require '../../bower_components/nib/index.styl'
@require '../../bower_components/normalize.css/normalize.css'

json('./vars.json')

@require './layout.styl'

//
// Base Styles
//

html
  font-size: 62.5% /* 10px browser default */

body
  background: $grey200
  color: $black87

a
  text-decoration: none
  color: $black54
  -webkit-tap-highlight-color: $transparent

h1
  font-size: $fontSizeHeadline

h2
  font-size: $fontSizeSubhead

button
  border: none
  outline: none
  appearance: none
  -webkit-tap-highlight-color: $transparent
